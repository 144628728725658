/**
 * @file app.less
 */
* {
  scroll-behavior: smooth;
}
html {
  font-size: 100px;
}
html.iphone {
  font-size: 100px;
  height: auto !important;
}
html.android {
  font-size: 90px;
}
@media screen and (max-width: 360px) {
  html.iphone {
    font-size: 90px;
  }
}
@media screen and (min-width: 750px) {
  html {
    background-color: #f5f6f9;
  }
}
body {
  margin: 0 auto;
  min-height: 100%;
  font-size: 14px;
  font-family: -apple-system-font, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
}
#app {
  height: 100%;
}
.drag-wrap {
  z-index: 101 !important;
}
.drag-wrap .drag-bottom {
  bottom: 34px;
}
.dragPosBtn {
  width: 1px;
  height: 1px;
}
/* 开放标签 */
wx-open-launch-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  z-index: 99 !important;
}
/* 组件中已important，这里兜底 */
wx-open-launch-app[tag='pos_fusion_dt_globalMask'] {
  z-index: 97 !important;
}
